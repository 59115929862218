.layOut {
  min-height: 100vh;
}

.avatar{
   margin-right: 2%;
   float: right;
}

.logo {
  text-align: center;
  color: white;
}

.header {
  padding: 0;
  background: rgb(255, 255, 255);
}

.content {
  margin: 0 16px;
}

.contentItem {
  padding: 24px;
  /* min-height: 100vh; */
  background: rgb(255, 255, 255);
}

.search{
    padding: 24px;
    margin-bottom: 2%;
    background: rgb(255, 255, 255);
}

.breadCrumb {
  margin: 16px 0;
}

.boderrow{
  border-bottom: 1px solid rgba(228, 215, 215, 1);
  padding-bottom: 1%;
}

.custom-card{
  margin-top: 3%;
  margin-bottom: 3%;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  background: #dee0b1;
}

.custom-card:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.menu-toggle{
  display: none;
}




@media (max-width: 1174px) {
  .menu{
    display: none;
  }
  
  .slider{
    display: none;
  }

  .menu-toggle{
    display: inline-block;
  }

  .firstItem {
    flex-direction: column; /* Đặt hướng xếp cột cho form và các thành phần bên trong */
  }

  .input{
    width: 100%;
    margin-bottom: 5%;
  }

  .formtole{
    display: block;
  }
  
  
}